import { useEffect } from 'react';

import type { Routine } from '@sb/types';
import { useVisualizerChildren } from '@sbrc/hooks';

import type { WidgetState } from '../types';

import { EnvironmentObjects } from './environment-objects/EnvironmentObjects';
import { VisualizeSpaceItem } from './VisualizeSpaceItem';

const POSITIONS_KEY = 'spaceItemPositions';
const OBJECTS_KEY = 'environmentObjects';

interface UseVisualizeSpaceArgs {
  widgetState?: WidgetState;
  setWidgetState?: (newState: WidgetState) => void;
  spaceItems?: Routine.SpaceItem[];
}

export function useVisualizeSpace({
  widgetState,
  setWidgetState,
  spaceItems,
}: UseVisualizeSpaceArgs) {
  const { setVisualizerChild } = useVisualizerChildren();

  useEffect(() => {
    setVisualizerChild(
      POSITIONS_KEY,
      widgetState ? <VisualizeSpaceItem widgetState={widgetState} /> : null,
    );
  }, [widgetState, setVisualizerChild]);

  useEffect(() => {
    setVisualizerChild(
      OBJECTS_KEY,
      <EnvironmentObjects
        spaceItems={spaceItems}
        widgetState={widgetState}
        setWidgetState={setWidgetState}
      />,
    );
  }, [spaceItems, widgetState, setWidgetState, setVisualizerChild]);

  useEffect(() => {
    // remove visualization on unmount
    return () => {
      setVisualizerChild(POSITIONS_KEY, null);
      setVisualizerChild(OBJECTS_KEY, null);
    };
  }, [setVisualizerChild]);
}
