import { ZERO_POSE } from '@sb/geometry';
import type { Routine } from '@sb/types';

import type { WidgetState } from '../../types';

import { EnvironmentObject } from './EnvironmentObject';
import { EnvironmentObjectPivotControls } from './EnvironmentObjectPivotControls';

interface EnvironmentObjectsProps {
  spaceItems?: Routine.SpaceItem[];
  widgetState?: WidgetState;
  setWidgetState?: (state: WidgetState) => void;
}

export function EnvironmentObjects({
  spaceItems,
  widgetState,
  setWidgetState,
}: EnvironmentObjectsProps) {
  if (!spaceItems) {
    return null;
  }

  return (
    <>
      {spaceItems.map((spaceItem) => {
        if (spaceItem.kind !== 'environmentObject' || !spaceItem.fileURL) {
          return null;
        }

        if (setWidgetState && spaceItem.id === widgetState?.item?.id) {
          return (
            <EnvironmentObjectPivotControls
              key={spaceItem.id}
              onDragEnd={(pose) => {
                const item = { ...spaceItem, pose };
                setWidgetState({ ...widgetState, item, updateItem: item });
              }}
              initialPose={spaceItem.pose}
            >
              <EnvironmentObject
                environmentObject={{ ...spaceItem, pose: ZERO_POSE }}
              />
            </EnvironmentObjectPivotControls>
          );
        }

        return (
          <EnvironmentObject key={spaceItem.id} environmentObject={spaceItem} />
        );
      })}
    </>
  );
}
