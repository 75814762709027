import { Suspense, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import type { Routine } from '@sb/types';
import { LoaderFallback } from '@sb/visualizer';

import { InnerFBXObject } from './InnerFBXObject';
import { InnerGLTFObject } from './InnerGLTFObject';
import { InnerOBJObject } from './InnerOBJObject';
import { InnerSTLObject } from './InnerSTLObject';

interface EnvironmentObjectProps {
  environmentObject: Routine.EnvironmentObject;
}

export function EnvironmentObject({
  environmentObject,
}: EnvironmentObjectProps) {
  const InnerObject = useMemo(() => {
    const extension = environmentObject.fileName
      ?.match(/\.\w+$/)?.[0]
      .toLowerCase();

    switch (extension) {
      case '.fbx':
        return InnerFBXObject;
      case '.glb':
      case '.gltf':
        return InnerGLTFObject;
      case '.obj':
        return InnerOBJObject;
      case '.stl':
        return InnerSTLObject;
      default:
        return null;
    }
  }, [environmentObject.fileName]);

  if (!InnerObject) {
    return null;
  }

  return (
    <Suspense fallback={<LoaderFallback />}>
      <ErrorBoundary fallback={<LoaderFallback />}>
        <InnerObject environmentObject={environmentObject} />
      </ErrorBoundary>
    </Suspense>
  );
}
